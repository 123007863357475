import React from "react"
import SEO from "../components/seo"
import styled from "styled-components"
import { Link } from "gatsby"

const Styled404 = styled.div`
  position: absolute;
  height: 100vh;
  width: 100vw;
  max-height: 100vh;
  max-width: 100vw;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;

  padding: 20vh 20vw;

  background-color: white;
  p {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  a {
    color: initial;
  }

  /* Responsive */

  /* mobile */
`

const FixedBackground = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  z-index: -1;
`

const NotFoundPage = () => (
  <Styled404>
    <FixedBackground />
    <SEO title="404: Not found" />
    <h1>Error 404: Not Found</h1>
    <p>
      The page you're trying to reach doesn't exist.
      <br />
      <Link to="/">Link to the homepage</Link>
    </p>
  </Styled404>
)

export default NotFoundPage
